/* eslint max-lines: ["error", 500]*/
import "core-js/stable";
import "regenerator-runtime/runtime";
import "bxslider/dist/jquery.bxslider.min";
import "bxslider/dist/jquery.bxslider.min.css";

window.$(document).ready(function () {
  const videos = [];
  window
    .$(".c-index-visual__slider")
    .children("li")
    .each(function (index, ele) {
      videos.push(
        $(ele).find("video").length ? $(ele).find("video").get(0) : null
      );
    });
  const $slider = window.$(".c-index-visual__slider").bxSlider({
    infiniteLoop: true,
    auto: true,
    touchEnabled: false,
    onSliderLoad() {
      videos.forEach((val) => {
        if (!val) return;
        val.pause();
        val.currentTime = 0;
      });
      if (videos[0]) {
        videos[0].play();
      }
    },
    onSlideAfter(ele, old_index, new_index) {
      if (videos[new_index]) {
        videos[new_index].play();
      } else {
        $slider.startAuto();
      }
      if (videos[old_index]) {
        videos[old_index].pause();
        videos[old_index].currentTime = 0;
      }
    },
  });

  videos.forEach((val) => {
    if (!val) return;
    window.$(val).on("play", function () {
      $slider.stopAuto();
    });
    window.$(val).on("ended", function () {
      $slider.goToNextSlide();
    });
  });
  $(".c-gnavMenu").click(function () {
    $slider.redrawSlider();
  });
  $(window).scroll(function () {
    if ($(window).width() <= 1024) return;
    if ($(window).scrollTop() <= 80) {
      $slider.redrawSlider();
    }
  });
});

// News
(function ($) {
  const $filters = $(".c-index-newsHead__filter [data-filter]");
  let currentCategory = "";

  $filters.on("click", function (e) {
    e.preventDefault();
    const $this = $(this);

    $filters.removeClass("_active_");
    $this.addClass("_active_");

    const $content = $(".news__content [data-category]"),
      filterCategory = $this.attr("data-filter");
    currentCategory = filterCategory;

    const $target =
      filterCategory === "all"
        ? $content.filter(":lt(8)")
        : $content.filter(`[data-category = "${filterCategory}"]:lt(8)`);

    $content
      .stop()
      .fadeOut()
      .promise()
      .done(function () {
        if (currentCategory === filterCategory) {
          $target.fadeIn();
        }
      });
  });
})(jQuery);

/* eslint max-lines-per-function: ["error", 200]*/
$(function () {
  const getBlogJson = function (json) {
      const d = new $.Deferred();
      $.ajax({
        type: "get",
        url: json,
        dataType: "json",
        success(_data) {
          d.resolve(_data);
        },
        error() {
          d.resolve([]);
        },
      });
      return d.promise();
    },
    time2Date = function (str) {
      return str.slice(0, 10).replace(/-/gu, "/");
    },
    createReportList = function (data) {
      const block = $("#news-pic-list"),
        list = block.find(".news__content");
      window._.each(data, function (obj, i) {
        const { title } = obj,
          url = obj.permalink,
          target = obj.blank ? ' target="_blank"' : "",
          category = obj.category_class,
          { thumbnail } = obj,
          datetime = obj.created,
          date = time2Date(obj.created),
          label1 = `<div class="e-label _h-s_ _${obj.category_class}_ __label">${obj.category}</div>`,
          label2 = obj.club_category
            ? `<p class="__club">${obj.club_category}</p>`
            : "",
          display = i > 7 ? 'style="display: none"' : "";
        list.append(
          $(
            `<a href="${url}"${target} class="panel c-news-list__item __col" data-category="${category}"${display}>
<div class="c-news-list__img">
<img src="${thumbnail}" alt="${title}" class="__elem">
</div>
<div class="c-news-list__body">
${label1}
<time datetime="${datetime}" class="__date">${date}</time>
${label2}
<p class="__text">${title}</p>
</div>
</a>`
          )
        );
      });
    };

  const ver = Math.random().toString(32).substring(2);
  $.when(getBlogJson(`/news/mt_news_list.json?${ver}`)).done(function (data1) {
    createReportList(data1);
  });
});
